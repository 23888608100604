// 开发环境域名

// const host_development = 'https://likeshopb2b2c.yixiangonline.com'
const host_development = 'https://api-kefu.fszgl.com'
const host_production = 'https://api-kefu.fszgl.com'

export default {
    // 版本
    version: '2.2.4.20230616',
    baseURL: process.env.NODE_ENV == 'production' ? host_production : host_development,
    //ChatWss: 'ws://192.168.112.129'
    ChatWss: 'ws://47.106.227.99'
}


